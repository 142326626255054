/* eslint-disable max-len */
import { IAMLaborTime } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetIAMLaborTimesResponse';
import { LaborTime as DHLaborTime } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimesResponse';
import { LinkedReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { LaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/LaborTime';
import {
  CatalogSource,
  Origin,
  ReferenceSource,
} from '@1po/1po-bff-fe-spec/generated/estimate/request/model/Reference';
import { Tire } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/Tire';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { RequestReference } from 'domains/estimate/Estimate.types';
import { ReferencePriceType } from 'domains/references';
import {
  getLaborDetailList,
  getLaborTimeRate,
  MECHANICS,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';

export const convertDHLaborTimeToEstimateLaborTime = (
  laborTimeLocal: DHLaborTime,
  estimateSettings: Settings,
): LaborTime => {
  return convertDHLaborTimeToEstimateLaborTimeWithQuantity(laborTimeLocal, 1, estimateSettings);
};

export const convertIAMLaborTimeToEstimateLaborTime = (
  laborTimeLocal: IAMLaborTime,
  estimateSettings: Settings,
): LaborTime => {
  return convertIAMLaborTimeToEstimateLaborTimeWithQuantity(laborTimeLocal, 1, estimateSettings);
};

const convertDHLaborTimeToEstimateLaborTimeWithQuantity = (
  laborTimeLocal: DHLaborTime,
  quantity: number,
  estimateSettings: Settings,
): LaborTime => {
  const laborDetailList = getLaborDetailList(
    laborTimeLocal.time,
    laborTimeLocal.technicity,
    estimateSettings.laborPriceList,
  );

  const priceVATExcl = laborDetailList.reduce((total, laborDetail) => {
    if (!laborDetail.laborTimeRate) {
      return total;
    }
    const laborTimeRate = parseFloat(laborDetail.laborTimeRate);
    return isNaN(laborTimeRate) ? total : total + laborTimeRate * laborDetail.time;
  }, 0);

  const estimateRate =
    priceVATExcl && laborTimeLocal.time.totalHours > 0 ? priceVATExcl / laborTimeLocal.time.totalHours : undefined;

  return {
    catalogSource: 'DATAHUB',
    code: laborTimeLocal.code,
    designation: laborTimeLocal.designation.title ?? '',
    pricePerHour: estimateRate?.toString() ?? '',
    numberOfHours: laborTimeLocal.time.totalHours,
    quantity,
    priceVatExcluded: priceVATExcl.toString(),
    vatPercentage: estimateSettings?.vatRate,
    includedOperations: getOperationsDesignationsFromLaborTimes(laborTimeLocal.subLaborTimes?.includedLaborTimes ?? []),
    notIncludedOperations: getOperationsDesignationsFromLaborTimes(
      laborTimeLocal.subLaborTimes?.notIncludedLaborTimes ?? [],
    ),
    technicity: laborTimeLocal.technicity,
    time: laborTimeLocal.time,
  };
};

const convertIAMLaborTimeToEstimateLaborTimeWithQuantity = (
  laborTimeLocal: IAMLaborTime,
  quantity: number,
  estimateSettings: Settings,
): LaborTime => {
  const estimateRate = getLaborTimeRate(MECHANICS, laborTimeLocal.technicity, estimateSettings.laborPriceList)?.price;
  const estimateRateNumber = estimateRate ? Number(estimateRate) : 1;
  return {
    catalogSource: 'IAM',
    code: laborTimeLocal.id,
    designation: laborTimeLocal.designation ?? '',
    pricePerHour: estimateRate ?? '',
    numberOfHours: laborTimeLocal.time,
    quantity,
    priceVatExcluded: String(laborTimeLocal.time * estimateRateNumber),
    vatPercentage: estimateSettings?.vatRate,
    includedOperations: getOperationsDesignationsFromIAMLaborTimes(laborTimeLocal.includedLaborTimes ?? []),
    notIncludedOperations: getOperationsDesignationsFromIAMLaborTimes(laborTimeLocal.optionalLaborTimes ?? []),
    technicity: laborTimeLocal.technicity,
  };
};

export const convertCatalogTestLaborTimesToEstimateLaborTimes = (
  tests: DHLaborTime[] | undefined,
  testLaborTimeQuantities: Map<string, number>,
  estimateSettings: Settings,
): LaborTime[] => {
  if (!tests) return [];
  return tests
    .filter((test) => testLaborTimeQuantities.get(test.code))
    .map((test) => {
      return convertDHLaborTimeToEstimateLaborTimeWithQuantity(
        test,
        testLaborTimeQuantities.get(test.code) ?? 1,
        estimateSettings,
      );
    })
    .filter((x) => x);
};

export const convertReferenceToEstimateReference = (
  referenceNumber: string,
  designation: string,
  price: ReferencePriceType,
  isCompatible: boolean,
  catalogSource: CatalogSource,
  origin?: Origin,
  supplierCode?: string,
  referenceSource?: ReferenceSource,
  eprelUrl?: string,
  linkedReferences?: LinkedReference[],
): RequestReference => {
  return {
    referenceNumber,
    designation,
    catalogSource,
    unitPrice: price.clientView?.recommendedPriceVatExcluded?.toString() ?? '',
    quantity: 1,
    priceVatExcluded: price.clientView?.recommendedPriceVatExcluded?.toString() ?? '',
    vatPercentage: price.garageView?.vatPercentage ?? 20,
    garagePrice: price.garageView?.vatExcludedPrice?.toString() ?? '',
    discountRate: price.garageView?.discountRate ?? '',
    isCompatible,
    eprelUrl,
    origin,
    supplierCode,
    referenceSource,
    linkedReferences,
  };
};

export const convertTireToEstimateTire = (
  referenceNumber: string,
  designation: string,
  price: ReferencePriceType,
  isCompatible: boolean,
  brandId?: string,
  eprelUrl?: string,
  tireDimensions?: string,
): Tire => {
  return {
    referenceNumber,
    designation,
    catalogSource: 'DATAHUB',
    unitPrice: price.clientView?.recommendedPriceVatExcluded?.toString() ?? '',
    quantity: 1,
    priceVatExcluded: price.clientView?.recommendedPriceVatExcluded?.toString() ?? '',
    vatPercentage: price.garageView?.vatPercentage ?? 20,
    garagePrice: price.garageView?.vatExcludedPrice?.toString() ?? '',
    discountRate: price.garageView?.discountRate ?? '',
    brandId,
    isCompatible,
    eprelUrl,
    tireDimensions,
  };
};

const getOperationsDesignationsFromLaborTimes = (laborTimes: DHLaborTime[]): string[] => {
  return laborTimes.map((labor) => {
    if (!labor.designation || !labor.designation.title) {
      return labor.code ?? '';
    }
    if (!labor.code) {
      return labor.designation.title ?? '';
    }
    return `${labor.code} - ${labor.designation.title}`;
  });
};

const getOperationsDesignationsFromIAMLaborTimes = (laborTimes: IAMLaborTime[]): string[] => {
  return laborTimes.map((labor) => `${labor.id} - ${labor.designation}`);
};

export const convertCatalogIAMLaborTimeToEstimateLaborTime = (
  laborTimeLocal: IAMLaborTime,
  estimateSettings: Settings,
): LaborTime => {
  return convertCatalogIAMLaborTimeToEstimateLaborTimeWithQuantity(laborTimeLocal, 1, estimateSettings);
};

const convertCatalogIAMLaborTimeToEstimateLaborTimeWithQuantity = (
  laborTimeLocal: IAMLaborTime,
  quantity: number,
  estimateSettings: Settings,
): LaborTime => {
  const estimateRate = getLaborTimeRate(MECHANICS, laborTimeLocal.technicity, estimateSettings.laborPriceList)?.price;
  const estimateRateNumber = estimateRate ? Number(estimateRate) : 1;
  return {
    catalogSource: 'IAM',
    code: laborTimeLocal.id,
    designation: laborTimeLocal.designation ?? '',
    pricePerHour: estimateRate ?? '',
    numberOfHours: laborTimeLocal.time,
    quantity,
    priceVatExcluded: String(laborTimeLocal.time * estimateRateNumber),
    vatPercentage: estimateSettings?.vatRate,
    includedOperations: getIAMOperationsDesignationsFromLaborTimes(laborTimeLocal.includedLaborTimes ?? []),
    notIncludedOperations: getIAMOperationsDesignationsFromLaborTimes(laborTimeLocal.optionalLaborTimes ?? []),
    technicity: laborTimeLocal.technicity,
  };
};

const getIAMOperationsDesignationsFromLaborTimes = (laborTimes: IAMLaborTime[]): string[] => {
  return laborTimes.map((labor) => `${labor.id} - ${labor.designation}`);
};
